import styles from './slider.module.scss';
import { forwardRef } from 'react';
import { BaseSliderProps } from '../../types/base-slider-props';
import cn from 'classnames';

export interface SliderProps extends BaseSliderProps {
  min?: number;
  max?: number;
  step?: number;
  fullWidth?: boolean;
  contrasted?: boolean;
}

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
  function Slider(props, ref) {
    const {
      min = 0,
      max = 500,
      step = 1,
      value,
      onChange,
      fullWidth = false,
      contrasted = false,
      disabled = false,
      className,
    } = props;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
      if (newValue === '' || (!isNaN(newValue) && newValue >= min && newValue <= max)) {
        onChange(e);
      }
    };

    return (
      <div className={cn(styles['slider-container'], { [styles['full-width']]: fullWidth })}>
        <input
          type="range"
          ref={ref}
          className={cn(
            styles['slider'],
            { [styles['disabled']]: disabled },
            { [styles['contrasted']]: contrasted },
            className
          )}
          min={min}
          max={max}
          step={step}
          value={typeof value === 'number' ? value : 0}
          onChange={onChange}
          disabled={disabled}
        />
        <input
          type="number"
          className={styles['slider-input']}
          value={value}
          onChange={handleInputChange}
        />
      </div>
    );
  }
);
