import { useState } from 'react';
import { supabase } from '../../../constants/supabase-client';
import { SUPABASE_API_KEY, SUPABASE_URL } from '../../../constants/env-configs';

const EDGE_FUNCTION_URL = `${SUPABASE_URL}/functions/v1/checkIP`;

export function useDatabase() {
  const [isAllowed, setIsAllowed] = useState(true);
  const [fundsCheckPassed, setFundsCheckPassed] = useState(true);

  const checkIPRateLimit = async () => {
    try {
      const response = await fetch(EDGE_FUNCTION_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${SUPABASE_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: 'Functions' }),
      });

      const data = await response.json();
      setIsAllowed(data.allowed);

      return data.allowed;
    } catch (error) {
      console.error('Error checking IP rate limit:', error);
      setIsAllowed(false);
      return false;
    }
  };

  const checkFundsLimit = async (address: string, newFunds: number) => {
    try {
      const { data: userFunds, error } = await supabase
        .from('FaucetAddresses')
        .select('funds, funds_per_hour, timestamp')
        .eq('address', address)
        .single();

      if (error && error.code !== 'PGRST116') {
        console.error('Error fetching user funds:', error);
        return false;
      }

      const currentFunds = userFunds?.funds || 0;
      const currentTime = new Date();
      const lastTimestamp = new Date(userFunds?.timestamp || currentTime);
      const timeDiff = (currentTime.getTime() - lastTimestamp.getTime()) / (1000 * 60 * 60);
      console.log('lastTimestamp:', lastTimestamp);
      console.log('currentTime:', currentTime);
      const fundsPerHour = (timeDiff < 1) ? ((userFunds?.funds_per_hour || 0) + newFunds) : newFunds;
      console.log(userFunds?.funds_per_hour);
      console.log(timeDiff);

      
      if (fundsPerHour > 500) {
        setFundsCheckPassed(false);
        return false;
      }

      if (currentFunds + newFunds > 10000) {
        setFundsCheckPassed(false);
        return false;
      }

      setFundsCheckPassed(true);
      return true;
    } catch (error) {
      console.error('Error checking funds limit:', error);
      setFundsCheckPassed(false);
      return false;
    }
  };

  

  const updateFunds = async (address: string, funds: number) => {
    if (!isAllowed || !fundsCheckPassed) {
      console.warn('Prerequisite check failed');
      return;
    }

    try {
      const { data: existingUserFunds, error: fetchError } = await supabase
        .from('FaucetAddresses')
        .select('funds, funds_per_hour, timestamp')
        .eq('address', address)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      const currentFunds = existingUserFunds?.funds || 0;
      const newFunds = currentFunds + funds;
      const currentTime = new Date();
      const lastTimestamp = new Date(existingUserFunds?.timestamp || currentTime);
      const timeDiff = (currentTime.getTime() - lastTimestamp.getTime()) / (1000 * 60 * 60);
      const fundsPerHour = (timeDiff < 1) ? ((existingUserFunds?.funds_per_hour || 0) + funds) : funds;
      console.log(existingUserFunds?.funds_per_hour);
      const newTimestamp = (timeDiff < 1) ? lastTimestamp : currentTime;


      const { data, error } = await supabase
        .from('FaucetAddresses')
        .upsert({ address: address, funds: newFunds, timestamp: newTimestamp.toISOString(), funds_per_hour: fundsPerHour }, { onConflict: 'address' });

      if (error) {
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error updating funds:', error);
    }
  };

  return {
    isAllowed,
    fundsCheckPassed,
    updateFunds,
    checkIPRateLimit,
    checkFundsLimit,
  };
}
