import { useState, useCallback, useRef } from 'react';
import { SUPABASE_API_KEY, SUPABASE_URL, SITE_KEY } from '../../../constants/env-configs';
import { BoundTurnstileObject } from 'react-turnstile';

const VERIFY_URL = `${SUPABASE_URL}/functions/v1/cloudflare-turnstile`;

export function useTurnstile() {
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string|null>(null);
    const turnstileRef = useRef<BoundTurnstileObject | null>(null);

    const verifyTurnstile = useCallback(async (token: string) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(VERIFY_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${SUPABASE_API_KEY}`,
                },
                body: JSON.stringify({ token }),
            });

            if (!response.ok) {
                throw new Error('Server response not ok');
            }

            const data = await response.json();
            
            if (data.success) {
                setIsVerified(true);
                return true;
            } else {
                setIsVerified(false);
                setError('Verification failed');
                return false;
            }
        } catch (error) {
            console.error('Error verifying Turnstile:', error);
            setIsVerified(false);
            setError('Error during verification');
            return false;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const resetTurnstile = useCallback(() => {
        setIsVerified(false);
        setError(null);
        if (turnstileRef.current) {
            turnstileRef.current.reset();
        }
    }, []);

    const onLoad = useCallback((_: string, boundTurnstile: BoundTurnstileObject) => {
        turnstileRef.current = boundTurnstile;
    }, []);

    return {
        siteKey: SITE_KEY,
        isVerified,
        isLoading,
        error,
        verifyTurnstile,
        resetTurnstile,
        onLoad,
    };
}