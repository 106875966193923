import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { http, createConfig } from 'wagmi';
import { JSON_RPC_PROVIDER, PROJECT_ID, MAINNET_RPC_PROVIDER } from './env-configs';
import { QueryClient } from '@tanstack/react-query';
import { mainnet, sepolia } from 'viem/chains';
import { privateKeyToAccount } from 'viem/accounts'
import { PRIV_KEY } from './env-configs';

export const wagmiConfig = createConfig({
  chains: [mainnet, sepolia],
  transports: {
    [mainnet.id]: http(MAINNET_RPC_PROVIDER),
    [sepolia.id]: http(JSON_RPC_PROVIDER),
  },
});

export const queryClient = new QueryClient();

export const mainAccount = privateKeyToAccount(PRIV_KEY);

