import { ANYONE_TOKEN, CONTRACT_ADDRESS, MAINNET_ANYONE_TOKEN } from '../../../../constants/env-configs';
import { ABI } from '../../../../constants/abi';
import { ANYONE_ABI } from '../../../../constants/anyoneABI';
import { useState , useEffect} from 'react';
import { parseEther, formatEther } from 'viem';
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
  useReadContract,
} from 'wagmi';
import { useDialog } from '../../../dialog/hooks/use-dialog';
import { useDatabase } from '../../../db/hooks/use-database';
import { BaseError } from 'wagmi';
import { mainnet, sepolia } from 'viem/chains';
import { mainAccount } from '../../../../constants/web3-config';
import { useTurnstile } from '../../../cloudflare/hooks/use-turnstile';

export function useClaim() {
  const { address } = useAccount();

  const {
    closeDialog: closeFundDialog,
    isDialogOpen: isFundDialogOpen,
    openDialog: openFundDialog,
  } = useDialog();

  const { isAllowed, fundsCheckPassed, updateFunds, checkIPRateLimit, checkFundsLimit } = useDatabase();
  const [toAddress, setToAddress] = useState<`0x${string}` | string>('');
  const [amount, setAmount] = useState<number>(100);
  const [etherAmount, setEtherAmount] = useState<number>(0.01);
  const [dbError, setDBError] = useState<string | null>(null);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);

  const { siteKey, isVerified, verifyTurnstile, resetTurnstile } = useTurnstile();

  const {
    writeContract,
    data: writeContractData,
    error,
    isPending,
    reset,
    status,
  } = useWriteContract();

  const { 
    data: tokens, 
    isLoading: isTokenLoading
  } = useReadContract({
    account: mainAccount,
    chainId: mainnet.id,
    address: MAINNET_ANYONE_TOKEN,
    abi: ANYONE_ABI,
    functionName: 'balanceOf',
    args: [toAddress],
    query: {
      refetchInterval: 1000,
    },
  });
  
  
  const {
    data: receiptData,
    error: receiptError,
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isError: isReceiptError,
    status: receiptStatus,
  } = useWaitForTransactionReceipt({
    chainId: sepolia.id,
    hash: writeContractData,
    pollingInterval: 500
  });
  

    useEffect(() => {
      const updateFundsOnConfirmation = async () => {
        if (isConfirmed && toAddress && amount) {
          try {
            await updateFunds(toAddress, amount);
          } catch (updateError) {
            setDBError('Something went wrong. Please try again later.');
          }
        }
      };
  
      updateFundsOnConfirmation();
    }, [isConfirmed, toAddress, amount, updateFunds, closeFundDialog]);

  const fundAccount = async () => {
    openFundDialog();

    const turnstileVerified = await verifyTurnstile(turnstileToken as string);
    if (!turnstileVerified) {
      setDBError("CAPTCHA verification failed. Please try again.");
      return;
    }


    if (isNaN(amount)) {
      setDBError("Tokens to recieve cannot be empty")
    }

    if (tokens !== undefined) {
      // Assuming the token has 18 decimals. Adjust this if your token has a different number of decimals.
      const tokenBalance = Number(formatEther(tokens as bigint));

      if (tokenBalance < 100) {
        setDBError("The receiver must hold 100 tokens or more on mainnet");
        return;
      }
    } else if (!isTokenLoading) {
      setDBError("Unable to fetch token balance. Please try again.");
      return;
    }

    const ipCheckPassed = await checkIPRateLimit();
    const fundsCheckPassed = await checkFundsLimit(toAddress, amount);

    if (!ipCheckPassed || !fundsCheckPassed) {
      setDBError(
        !ipCheckPassed
          ? 'You have exceeded the rate limit. Try again later'
          : 'Funds limit exceeded. Try again later.'
      );
      // openFundDialog();
      return;
    }

    openFundDialog();
      writeContract({
        account: mainAccount,
        chainId: sepolia.id,
        address: CONTRACT_ADDRESS,
        abi: ABI,
        functionName: 'fund',
        args: [toAddress, parseEther(amount.toString()), parseEther(etherAmount.toString())],
      });

      // await updateFunds(toAddress, amount);
  };

  return {
    toAddress,
    setToAddress,
    amount,
    setAmount,
    etherAmount,
    setEtherAmount,
    fundAccount,
    isPending,
    isConfirming,
    isConfirmed,
    dbError,
    error,
    status,
    reset,
    closeFundDialog,
    isFundDialogOpen,
    setTurnstileToken,
    turnstileToken,
  };
}
