import { useState } from "react";

export function useDialog() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function closeDialog() {
    setIsDialogOpen(false);
  }
  function openDialog() {
    setIsDialogOpen(true);
  }

  return {
    isDialogOpen,
    closeDialog,
    openDialog,
  };
}
