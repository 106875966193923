import styles from './dialog.module.scss';
import {
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
} from '@mui/material';
import { ReactNode } from 'react';

export interface DialogProps extends Omit<MUIDialogProps, 'open'> {
  visible: boolean;
  hide: () => void;
  children?: ReactNode;
}

export function Dialog(props: DialogProps) {
  const { visible, hide, children, className } = props;

  return (
    <MUIDialog
      open={visible}
      onClose={hide}
      maxWidth="sm"
      fullWidth
      className={className}
    >
      <div className={styles['container']}>{children}</div>
    </MUIDialog>
  );
}
